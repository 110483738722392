import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'custom_page_sections',
  attributes: [
    'id',
    'page_id',
    'category',
    'data',
    'position',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

const CATEGORY_DATA_MAP = {
  banner: ['use_type'],
  promoter_share: ['title', 'title_en', 'subtitle', 'promoter_share_ids'],
  sales_event: ['title', 'title_en', 'subtitle', 'use_type'],
  partner_plan: ['title', 'subtitle'],
  info_link: ['title', 'subtitle', 'use_type'],
  customer_service: ['title', 'subtitle'],
  product_category: ['title', 'title_en', 'subtitle', 'category_ids']
}

export default class CustomPageSection extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static categories() {
    return Object.keys(CATEGORY_DATA_MAP)
  }
}
