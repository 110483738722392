import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'custom_pages',
  attributes: [
    'id',
    'name',
    'use_type',
    'is_enabled',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['name', 'use_type']
}

const USE_TYPE_MAP = ['one_page', 'homepage']

export default class CustomPage extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static useTypes() {
    return USE_TYPE_MAP
  }

  toggleEnabled() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_enabled`)
  }

  saveSection({ customPageSection, form }) {
    if (customPageSection.isNewRecord()) {
      return axios.post(`${this.apiBasePath()}/${this.id}/sections`, {
        data: {
          type: 'create-section',
          attributes: form
        }
      })
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/sections/${customPageSection.id}`,
      {
        data: {
          type: 'update-section',
          attributes: form
        }
      }
    )
  }

  destroySection({ customPageSection }) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/sections/${customPageSection.id}`
    )
  }

  sortSection({ customPageSection, direction }) {
    return axios.put(`${this.apiBasePath()}/${this.id}/sort_section`, {
      data: {
        type: 'sort-section',
        attributes: {
          id: customPageSection.id,
          direction
        }
      }
    })
  }
}
